.redeem {
    .ScrollbarsCustom .ScrollbarsCustom-Content {
        padding-right: 20px !important;
    }

    .reward-grid {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
        padding-left: 10px;
        padding-bottom: 20px;

        .gift-card-item {
            &.active {
                background-color: #FF6666;

                .text-sub {
                    color: white
                }
            }
        }
    }
    
    .selected-item {
        border-radius: 20px;
        padding: 20px;
        margin-right: 45px;

        &.terms {
            .ScrollbarsCustom-Scroller {
                padding-right: 25px !important;
            }
        }

        .success {
            display: flex;
            flex-direction: column;
            align-items: center;

            .btn {
                width: 140px;
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .close {
            position: absolute;
            right: 30px;
            top: 20px;
            font-size: 1.5em;
            color: #666666;
            z-index: 3;
        }

        .popover-modal {
            border-radius: 20px;
        }
    
        .ScrollbarsCustom-Content,
        .ScrollbarsCustom-Scroller {
            display: flex;
        }

        img {
            max-height: 250px;
            object-fit: contain;
            min-width: 0;
        }

        .btn-negative-alt {
            padding-left: 70px;
            padding-right: 70px;
            cursor: default;
        }
    }
    
    .redeem-medal {
        min-height: 0;
        justify-content: center;
        display: flex;
        width: 40%;
        margin-top: 20px;
        margin-bottom: 20px;
    
        > img {
            object-fit: contain;
        }
    }
}