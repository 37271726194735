@import './Typography.scss';
@import './Buttons.scss';
@import './Modals.scss';
@import './Scrollbar.scss';
@import './Icons.scss';
@import './Loader.scss';
@import './Markdown.scss';

/* Basic Styles */
body {
    background: #FF0000;
    background: linear-gradient(0deg, red 25%, #FF6666 100%);
    animation: animatedGradient 45s infinite linear;
    background-size: 200% 200%;
}

@keyframes animatedGradient {
    0%{background-position: 0% 100%}
    50%{background-position: 100% 0%}
    100%{background-position: 0% 100%}
}

.page-container {
    height: calc(100vh - 100px);
    margin-top: 60px;
    padding: 20px;
}

.page-content {
    position: relative;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Chrome, Edge, Opera and Firefox */
}

/* Components */
.card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
}

.card-open {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.card-header {
    background-color: #FF9999;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;
    padding: 6px;
    padding-left: 12px;
    font-size: 1em;
    font-weight: bold;
}

.card-alert {
    background-color: #FFCCCC;
}

.profile-image-md {
    border-radius: 50%;
    width: 64px;
    height: 64px;
}

.profile-image-sm {
    border-radius: 50%;
    width: 55px;
    height: 55px;
}

.profile-image-x-sm {
    border-radius: 50%;
    width: 45px;
    height: 45px;
}

.profile-image-lg {
    border-radius: 50%;
    width: 128px;
    height: 128px;
}

.profile-image-xlg {
    border-radius: 50%;
    width: 180px;
    height: 180px;
}

.mini-card {
    height: 55px;
}

input,
select,
textarea,
.textarea-backing {
    color: red;
    font-family: 'Wales Sans Body', sans-serif;
    border: 1px solid #D2D2D2;
    font-size: 1.2em;
    padding: 10px;
    padding-left: 15px;
    border-radius: 8px;
}

textarea,
.textarea-backing {
    color: red;
    max-width: 100%;
    max-height: 100%;
    min-height: 30px;
}

textarea {
    resize: none;
}

input::placeholder,
select::placeholder,
textarea::placeholder,
select:invalid {
    color: #CCCCCC
}

select > option {
    color: red;
}

/* Reusable Classes */
.fixed {
    position: fixed;
}

.z-index-1 {
    z-index: 1;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex {
    display: flex;
    flex-direction: column;
}

.flex-grow {
    flex-grow: 1;
}

.flex-align-center {
    align-items: center;
}

.flex-justify-center {
    justify-content: center;
}

.flex-justify-start {
    justify-content: flex-start;
}

.flex-center-both {
    justify-content: center;
    align-items: center;
}

.flex-center-self {
    align-self: center;
}

.flex-align-start {
    align-items: flex-start;
}

.flex-clip-text {
    min-width: 0;

    > * {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.flex-space-between {
    justify-content: space-between;
}

.flex-space-around {
    justify-content: space-around;
}

.flex-space-evenly {
    justify-content: space-around;

    @supports not (-ms-ime-align: auto) {
        justify-content: space-evenly;
    }
}

.flex-item-bottom {
    margin-top: auto;
}

.flex-item-right {
    margin-left: auto;
}

.flex-item-left {
    margin-right: auto;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.flex-shrink-1 {
    flex-shrink: 1;
}

.flex-basis-33 {
    flex-basis: 33%;
}

.flex-basis-66 {
    flex-basis: 66%;
}

.flex-basis-50 {
    flex-basis: 50%;
}

.min-width-0 {
    min-width: 0;
}

.padding-0 {
    padding: 0;
}

.padding-1 {
    padding: 10px;
}

.padding-2 {
    padding: 20px;
}

.padding-3 {
    padding: 30px;
}

.padding-4 {
    padding: 40px;
}

.padding-6 {
    padding: 60px;
}

.margin-0 {
    margin: 0;
}

.margin-top-first-0:first-of-type {
    margin-top: 0;
}

.margin-1 {
    margin: 10px;
}

.margin-2 {
    margin: 20px;
}

.margin-3 {
    margin: 30px;
}

.padding-right-0 {
    padding-right: 0px;
}

.padding-right-1 {
    padding-right: 10px;
}

.padding-right-2 {
    padding-right: 20px;
}

.padding-right-3 {
    padding-right: 30px;
}

.padding-left-0 {
    padding-left: 0px;
}

.padding-left-1 {
    padding-left: 10px;
}

.padding-left-2 {
    padding-left: 20px;
}

.padding-left-3 {
    padding-left: 30px;
}

.padding-bottom-0 {
    padding-bottom: 0px;
}


.padding-bottom-1 {
    padding-bottom: 10px;
}

.padding-bottom-2 {
    padding-bottom: 20px;
}

.padding-bottom-3 {
    padding-bottom: 30px;
}

.padding-bottom-4 {
    padding-bottom: 40px;
}

.padding-top-0 {
    padding-top: 1px;
}

.padding-top-1 {
    padding-top: 10px;
}

.padding-top-2 {
    padding-top: 20px;
}

.padding-top-3 {
    padding-top: 30px;
}

.padding-top-4 {
    padding-top: 40px;
}

.margin-left-auto {
    margin-left: auto;
}

.margin-left-1 {
    margin-left: 10px;
}

.margin-left-2 {
    margin-left: 20px;
}

.margin-left-3 {
    margin-left: 30px;
}

.margin-left-4 {
    margin-left: 40px;
}

.margin-left--1 {
    margin-left: -10px;
}

.margin-right-1 {
    margin-right: 10px;
}

.margin-right-2 {
    margin-right: 20px;
}

.margin-right-3 {
    margin-right: 30px;
}

.margin-right-4 {
    margin-right: 40px;
}

.margin-right-5 {
    margin-right: 50px;
}

.margin-top--1 {
    margin-top: -10px;
}

.margin-top-0 {
    margin-top: 0px;
}

.margin-top-1 {
    margin-top: 10px;
}

.margin-top-2 {
    margin-top: 20px;
}

.margin-top-3 {
    margin-top: 30px;
}

.margin-top-4 {
    margin-top: 40px;
}

.margin-bottom-0 {
    margin-bottom: 0px;
}

.margin-bottom-1 {
    margin-bottom: 10px;
}

.margin-bottom-2 {
    margin-bottom: 20px;
}

.margin-bottom-3 {
    margin-bottom: 30px;
}

.margin-bottom-4 {
    margin-bottom: 40px;
}

.no-wrap {
    white-space: nowrap;
}

.spacing-1 {
    margin-left: 5px;
    margin-right: 5px;
}

.pointer {
    cursor: pointer;
}

.underline {
    text-decoration: underline;
}

.inline-block {
    display: inline-block;
}

.width-50 {
    width: 50%;
}

.width-33 {
    width: 33.33%;
}

.full-width {
    width: 100%;
    box-sizing: border-box;
}

.full-height {
    height: 100%;
    box-sizing: border-box;
}

.border-box {
    box-sizing: border-box;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.overflow-y {
    overflow-y: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.primary-background {
    background-color: red;
}

.secondary-background {
    background-color: white;
}

.negative-background {
    background-color: #EFEFEF;
}

.white-background {
    background-color: white;
}

.border-bottom {
    border-bottom: 1px solid red;
}

.border-right-dark {
    border-right: 1px solid #CCCCCC;
}

.border-bottom-alt {
    border-bottom: 1px solid white;
}

.border-top {
    border-top: 1px solid red;
}

.border-alt {
    border-color: white;
}

.min-height-0 {
    min-height: 0;
}

.img-full-height {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: 100%;
}

.top-left {
    top: 0;
    left: 0;
}

.bottom-left {
    bottom: 0;
    left: 0;
}

.hidden {
    visibility: hidden;
}

.box-shadow {
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
}

.border-radius-20 {
    border-radius: 20px;
}

.center-text {
    text-align: center;
}

.tippy-tooltip-content {
    background-color: black;
    color: white;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 8px;
    text-align: center;
    border: 2px solid #DDDDDD;

    .tooltip-title {
        font-size: 1em;
    }

    .tooltip-text {
        font-size: 0.9em;
        font-weight: 100;
    }
}

.alert-bar {
    background-color: #FF9999;
    color: white;
    border-radius: 20px;
    padding: 20px;
    margin: 10px;
    margin-top: 0px;
    font-weight: bold;
    display: flex;
    align-items: center;

    .alert-icon {
        font-size: 24px;
        margin-right: 10px;
    }
}

.browser-alert {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    border-radius: 0;
    display: flex;
    justify-content: center;
}

::-webkit-file-upload-button {
    cursor:pointer;
}

input[type=file] {
    cursor:pointer;
}

.selector {
    margin: 20px;
    border-radius: 35px;

    .selector-item:first-of-type {
        border-top-left-radius: 35px;
        border-bottom-left-radius: 35px;
    }

    .selector-item:last-of-type {
        border-top-right-radius: 35px;
        border-bottom-right-radius: 35px;
    }

    .selector-item {
        font-weight: bold;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: white;
        color: #FF0000;
        cursor: pointer;

        &.active {
            background-color: #FF0000;
            color: white;
        }

        &.disabled {
            color: #999999;
            background-color: #D2D2D2;
        }
    }
}

/* class applies to select element itself, not a wrapper element */
.select-css {
    display: block;
    font-size: 14px;
    color: #444;
    line-height: 1.3;
    padding: .6em 1.4em .5em .8em;
    text-overflow: ellipsis;
    padding-right: 20px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: .5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
  }
  /* Hide arrow icon in IE browsers */
  .select-css::-ms-expand {
    display: none;
  }
  /* Hover style */
  .select-css:hover {
    border-color: #888;
  }
  /* Focus style */
  .select-css:focus {
    border-color: #aaa;
    /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222; 
    outline: none;
  }

  .select-wrapper {
      position: relative;
      width: 100%;

      .chevron {
        color: red;
        display: inline-block;
        position: absolute;
        right: 10px;
        top: 10px;
        pointer-events: none;
      }
  }
  
  /* Set options to normal weight */
  .select-css option {
    font-weight: normal;
    color: #444;
  }
  
  /* Support for rtl text, explicit support for Arabic and Hebrew */
  *[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
    background-position: left .7em top 50%, 0 0;
    padding: .6em .8em .5em 1.4em;
  }
  
  /* Disabled styles */
  .select-css:disabled, .select-css[aria-disabled=true] {
    color: graytext;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
      linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  }
  
  .select-css:disabled:hover, .select-css[aria-disabled=true] {
    border-color: #aaa;
  }

/* Card ellipsis menus */
.card-menu {
    position: absolute;
    right: 55px;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
    z-index: 1;

    >div:first-of-type {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    
    >div:last-of-type {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    >div {
        padding: 10px;
        padding-left: 15px;
        padding-right: 25px;
    }

    >div:hover {
       background-color: red;
       color: white;
    }
}