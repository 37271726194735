.limited-text-area {
    position: relative;

    > textarea, .textarea-backing {
        width: 100%;
        box-sizing: border-box;
    }

    > textarea {
        z-index: 1;
        background-color: transparent;
        position: absolute;
        top: 0;
        height: 100%;
        overflow: hidden;
        color: #666666;
    }

    .textarea-backing {
        border-color: transparent;
        position: relative;
        margin: 0;
        color: transparent;
        min-height: 70px;
        white-space: pre-wrap;
        .highlight {
            background-color: #FF6666;
        }
    }

    > .count {
        width: 100%;
        text-align: left;
        color: #666666;
        margin: 0;
        margin-bottom: -20px;
        margin-left: 5px;
        top: 2px;
        position: relative;

        &.max {
            color: red;
            font-weight: bold;
        }

        >svg {
            margin-left: 4px;
        }
    }
}