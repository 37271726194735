.create-post {
    background-color: white;
    padding: 10px;

    .btn {
        width: 90px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 10px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .image-preview {
        max-height: 500px;
        object-fit: contain;
        max-width: 90%;
    }

    input {
        color: red;
        border: 1px solid #E5E5E5;
        background-color: white;
        border-radius: 15px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    input::placeholder {
        color: #CCCCCC;
    }

    label {
        color: red;
        margin-top: 15px;
    }

    .post-type-selector {
        margin-left: 0;
        margin-right: auto;
        margin-top: 10px;

        .selector-item {
            font-size: 1em;
        }
    }

    .SingleDatePicker {
        z-index: 10;

        .SingleDatePickerInput {
            border: none;

            .DateInput {
                width: 95px;
    
                .DateInput_input {
                    font-size: 1em;
                    padding: 5px;
                    padding-left: 10px;
                    font-weight: bold;
                }
            }
        }

        // Will edit everything selected including everything between a range of dates
        .CalendarDay__selected_span {
            background: red;
            color: white;
            border: 1px solid #FFCCCC;
        }
        
        // Will edit selected date or the endpoints of a range of dates
        .CalendarDay__selected {
            background: red;
            color: white;
            border: 1px solid #FFCCCC;
        }

        .CalendarDay__selected:hover {
            background: #FFCCCC;
            color: white;
        }
        
        .CalendarDay__selected_span:hover {
            background: #FFCCCC;
            color: white;
            border: 0px solid red;
        }

        .CalendarDay__hovered_span {
            background: #FFCCCC;
            color: white;
            border: 0px solid red;
        }

        .CalendarDay__hovered_span:hover {
            background: #FFCCCC;
            color: white;
            border: 1px solid red;
        }

        .DayPickerKeyboardShortcuts_show__bottomRight::before {
            border-right: 33px solid red;
        }
    }

    .tooltipped-label {
        label {
            margin-top: 0;
        }
    }

    .rte {
        margin-top: 10px;
        margin-bottom: 10px;
        flex: 1;
        border-color:  #E5E5E5;
        min-height: 200px;
        border-radius: 8px;

        select, select > option {
            color: black;
        }

        > div {
            border-color:  #E5E5E5;
        }
    }

    .upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;
        margin-right: auto;

        > .btn {
            background-color: red;
            color: white;
        }
    }
    
    .upload-btn-wrapper input[type=file] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }

    
}

.create-post-wrapper {
    height: 100%;

    .ScrollbarsCustom-Content {
        position: relative;
    }

    .popover-modal {
        z-index: 11;
    }
}