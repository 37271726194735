.reporting {
    background-color: white;
    border-radius: 20px;
    padding: 20px;

    * {
        box-sizing: border-box;
    }

    .report-card {
        border-radius: 10px;
        margin-right: 10px;
        margin-top: 10px;
        border: 2px solid #FFFFFF;
        outline: none;
        background-color: white;

        &.active {
            border: 2px solid #FF9999;
        }
    }

    .report-container {
        height: calc(100% - 40px);
    }

    .button-scroll {
        width: 365px !important;

        .ScrollbarsCustom-Content {
            padding-left: 10px !important;
            padding-bottom: 10px !important;
            padding-top: 5px !important;
            padding-right: 0px !important;
        }

        .ScrollbarsCustom-Scroller {
            padding-right: 25px !important;
        }
    }

    .data-table {
        thead {
            background-color: #FFCCCC;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }

        thead th {
            position: sticky;
            top: 0;
            background-color: #FFCCCC;
        }

        td,
        th {
            padding: 10px;
        }
    }

    // Will edit everything selected including everything between a range of dates
    .CalendarDay__selected_span {
        background: red;
        color: white;
        border: 1px solid #FFCCCC;
    }
    
    // Will edit selected date or the endpoints of a range of dates
    .CalendarDay__selected {
        background: red;
        color: white;
        border: 1px solid #FFCCCC;
    }

     .CalendarDay__selected:hover {
        background: #FFCCCC;
        color: white;
    }
    
    .CalendarDay__selected_span:hover {
        background: #FFCCCC;
        color: white;
        border: 0px solid red;
    }

    .CalendarDay__hovered_span {
        background: #FFCCCC;
        color: white;
        border: 0px solid red;
    }

    .CalendarDay__hovered_span:hover {
        background: #FFCCCC;
        color: white;
        border: 1px solid red;
    }

    .DayPickerKeyboardShortcuts_show__bottomRight::before {
        border-right: 33px solid red;
    }

    .DateInput_input__focused {
        border-bottom: 2px solid red;
    }

    .DateRangePicker {
        margin-left: 40px;
    }

    .select-wrapper {
        margin-left: 25px;
        width: 150px;
    }

    .download-selector {
        padding: 20px;

        .label {
            font-size: 18px;
            margin-bottom: 10px;
        }

        .selector {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0;
        }
    }
}