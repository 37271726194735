.social {
    display: flex;
    flex-direction: column;
    flex: 1;
    
    .dash-scroll {
        margin-left: -20px;
        margin-right: -20px;

        .ScrollbarsCustom-Content {
            padding-left: 20px !important;
            padding-top: 0px !important;
            padding-bottom: 20px !important;
            padding-right: 20px !important;
        }

        .ScrollbarsCustom-Scroller {
            margin-right: 0px !important;
        }
    }

    .social-feed {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
}