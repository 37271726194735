.social-item {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);

    img {
        object-fit: contain;
        width: 100%;
        height: 275px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .date-text {
        font-size: 1em;
    }

    .rte {
        background-color: transparent;
        border: none;
        display: flex;
        flex: 1;
        max-height: 150px;
        min-height: 100%;

        .editor {
            overflow: hidden;
            position: relative;
            height: 100%;
            min-height: 75px;

            > div > div {
                z-index: 0;
            }

            > div > div > div {
                overflow: hidden !important;
            }
        }
    }

    .editor:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(to bottom, rgba(255,255,255, 0), white 90%);
        width: 100%;
        height: 4em;
    }

    .post-title {
        font-size: 2em;
        margin-bottom: 0.1em;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}