.app-links {
    min-height: 100vh;
    min-height: -webkit-fill-available;

    h1 {
        margin-top: 5%;
        margin-bottom: 5%;
        font-size: 64px;
    }

    .badge-row {
        margin-top: 5%;

        img {
            width: 100%;
            height: 60px;
            object-fit: contain;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}