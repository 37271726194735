@font-face {
    font-family: 'Wales Sans Body';
    src: url('../Assets/fonts/WalesSansBody-Medium.eot');
    src: local('Wales Sans Body Medium'), local('../Assets/fonts/WalesSansBody-Medium'),
        url('../Assets/fonts/WalesSansBody-Medium.eot?#iefix') format('embedded-opentype'),
        url('../Assets/fonts/WalesSansBody-Medium.woff2') format('woff2'),
        url('../Assets/fonts/WalesSansBody-Medium.woff') format('woff'),
        url('../Assets/fonts/WalesSansBody-Medium.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Wales Sans Body';
    src: url('../Assets/fonts/WalesSansBody-LightItalic.eot');
    src: local('Wales Sans Body Light Italic'), local('WalesSansBody-LightItalic'),
        url('../Assets/fonts/WalesSansBody-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../Assets/fonts/WalesSansBody-LightItalic.woff2') format('woff2'),
        url('../Assets/fonts/WalesSansBody-LightItalic.woff') format('woff'),
        url('../Assets/fonts/WalesSansBody-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Wales Sans Body';
    src: url('../Assets/fonts/WalesSansBody-Regular.eot');
    src: local('Wales Sans Body'), local('WalesSansBody-Regular'),
        url('../Assets/fonts/WalesSansBody-Regular.eot?#iefix') format('embedded-opentype'),
        url('../Assets/fonts/WalesSansBody-Regular.woff2') format('woff2'),
        url('../Assets/fonts/WalesSansBody-Regular.woff') format('woff'),
        url('../Assets/fonts/WalesSansBody-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Wales Sans Body';
    src: url('../Assets/fonts/WalesSansBody-Italic.eot');
    src: local('Wales Sans Body Italic'), local('WalesSansBody-Italic'),
        url('../Assets/fonts/WalesSansBody-Italic.eot?#iefix') format('embedded-opentype'),
        url('../Assets/fonts/WalesSansBody-Italic.woff2') format('woff2'),
        url('../Assets/fonts/WalesSansBody-Italic.woff') format('woff'),
        url('../Assets/fonts/WalesSansBody-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Wales Sans Body';
    src: url('../Assets/fonts/WalesSansBody-Light.eot');
    src: local('Wales Sans Body Light'), local('WalesSansBody-Light'),
        url('../Assets/fonts/WalesSansBody-Light.eot?#iefix') format('embedded-opentype'),
        url('../Assets/fonts/WalesSansBody-Light.woff2') format('woff2'),
        url('../Assets/fonts/WalesSansBody-Light.woff') format('woff'),
        url('../Assets/fonts/WalesSansBody-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: 'Wales Sans Body', sans-serif !important;
    color: red;
}

h1 {
    font-size: 6em;
}

h2 {
    color: #999999;
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.bold {
    font-weight: bold;
}

.text-alert {
    color: red;
}

.text-alt {
    color: white;
}

.text-grey {
    color: #999999;
}

.text-grey-2 {
    color: #666666;
}

.text-dark {
    color: #333333
}

.text-sub {
    color: #767676;
}

.text-link {
    color: red;
    text-decoration: underline;
    cursor: pointer;
}

.text-base {
    font-size: 0.9em;
}

.text-xsm {
    font-size: 0.6em;
}

.text-sm {
    font-size: 0.8em;
}

.text-md {
    font-size: 1.2em;
}

.text-lg {
    font-size: 1.4em;
}

.text-xlg {
    font-size: 2em;
}

.text-xxlg {
    font-size: 3em;
}

.italic {
    font-style: italic;
}

.light {
    font-weight: lighter;
}

.normal {
    font-weight: normal;
}