.social-item-admin {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;

    .title-text {
        font-size: 1em;
        font-weight: bold;
    }

    img {
        object-fit: contain;
        width: 100%;
        height: 275px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .date-text {
        font-size: 1em;
    }

    .rte {
        background-color: transparent;
        border: none;
        height: 200px;

        .editor {
            overflow: hidden;
            position: relative;
            height: 100%;

            > div > div {
                z-index: 0;
            }

            > div > div > div {
                overflow: hidden !important;
            }
        }
    }

    .editor:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(to bottom, rgba(255,255,255, 0), white 90%);
        width: 100%;
        height: 4em;
    }

    .post-title {
        font-size: 2.2em;
        margin-bottom: 0.1em;
        font-weight: bold;
    }
}