.wallet-modal {
    .wallet-list {
        width: 100%;
        height: 100%;
    }

    .ScrollbarsCustom {
        flex-grow: 1;
    }

    .ScrollbarsCustom-Content {
        padding-left: 10px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .heading-row {
        display: flex;
        flex-direction: row;
        padding-right: 30px;
        padding-left: 20px;

        .image-space {
            width: 95px;
        }

        .title-space,
        .heading-block {
            display: flex;
            flex: 1;
        }

        .heading-block {
            margin-bottom: 5px;
            margin-right: 10px;
            
            > p {
                text-align: center;
                width: 33.33%;
                color: #FF9999;
                margin-top: 0;
                margin-bottom: 2px;
            }
        }
    }
}
