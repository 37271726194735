.profile-modal {
    padding: 0 !important;
    z-index: 0 !important;

    > .modal-box {
        padding: 0 !important;
    }

    .content {
        height: 100%;
        background-color: transparent !important;

        .profile-bar {
            width: 35% !important;
            max-width: 500px;

            .ScrollbarsCustom-Content {
                display: flex;
                padding: 10px;
                padding-right: 15px !important;
            }

            .ScrollbarsCustom-Scroller {
                padding-left: 16px;
            }

            .card {
                width: 100%;
                margin-top: 20px;
            }

            .icon-circle {
                border-radius: 50%;
                width: 30px;
                height: 30px;
                background-color: #FF0000;
            }
        }
    }

    .scroll-background {
        background-color: white;

        .ScrollbarsCustom-Content {
            padding-left: 10px !important;
            padding-top: 10px !important;
            padding-bottom: 10px !important;
        }
    }
}