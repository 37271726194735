.badge-modal {
    .badge-header {
        padding-top: 28px;
    }

    .stat-circle {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        position: relative;

        >.value {
            margin-bottom: 8px;
            font-size: 2.6em;

            &.badge {
                position: relative;
                top: -5px;
            }
        }

        >.title {
            bottom: 12px;
            position: absolute;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            >.title {
                transform: translate(-50%, 0px);
            }
        }

        &.legendary {
            border: 5px solid orange;
        }
    }

    .badge-grid {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fit, minmax(504px, 1fr));
        padding-left: 10px;
        padding-bottom: 10px;
    }

    .badge-card {
        padding: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 20px;
        box-sizing: border-box;
        border-radius: 15px;
        border: 5px solid white;
        

        &.legendary {
            border: 5px solid orange;
            background: linear-gradient(90deg, orange 0px, #FFFFFF 175px);
        }

        .text-alt {
            color: red;

            &.legendary {
                color: orange;
            }
        }

        .description {
            font-size: 1em;
        }

        img {
            margin-right: 20px;
        }
    }
}