.wallet-item {
    &.card {
        padding: 10px;
        margin-top: 10px;
    }

    img {
        width: 75px;
        height: 50px;
        object-fit: contain;
        margin-right: 20px;
    }

    .row-block {
        display: flex;
        flex: 1;
        align-items: center;
        text-align: center;
    }

    .row-block:first-of-type {
        justify-content: flex-start;
    }

    .row-block:last-of-type {
        >p, >div {
            width: 33.33%;
        }

        >p {
            font-weight: lighter;
        }

        > div > a {
            margin-right: 5px;
        }

        a {
            color: red;
        }
    }
}