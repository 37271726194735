.kudos-item {
    .card {
        border-radius: 20px;
        position: relative;
        padding-right: 75px;
    }

    .card-menu {
        transform: none;
        top: 28px;
        right: 85px;
    }

    .comment-item .card-menu {
        right: 55px;
        top: 50%;
        transform: translateY(-50%);
    }

    .coins-icon {
        width: 18px;
        top: 6px;
        position: relative;
        margin-left: 2px;
        margin-right: 4px;
    }

    .admin-coin-count {
        margin-top: 6px;
        margin-bottom: -6px;
    }

    .ScrollbarsCustom {
        flex: 1;
        height: 80% !important;

        .ScrollbarsCustom-Scroller > .ScrollbarsCustom-Content {
            padding-bottom: 0px !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .ScrollbarsCustom-Wrapper::after {
        display: none;
    }

    .details-expand {
        width: calc(100% - 42px);
        box-sizing: border-box;
    }
}

.expander {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    height: 100%;
    width: 42px;
    background-color: #FF6666;
}

.details-expand {
    position: absolute;
    right: 42px;
    background-color: #FF6666;
    height: 100%;
    color: white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
}