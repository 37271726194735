.dashboard {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;

    .hub-logo {
        width: 72px;
        height: 72px;
        margin-bottom: 15px;
    }

    .nav-bar {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin: 40px;
        margin-bottom: 20px;
        min-width: 75px;
        max-width: 180px;
        width: 8%;

        .action-bar {
            display: flex;
            flex: 1;
            max-height: 550px;

            .actions {
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                .action {
                    text-align: center;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    cursor: pointer;
                    color: #FFFFFF;
                    opacity: 0.5;
                    transition: opacity 0.25s ease-in-out;

                    &.active {
                        opacity: 1;
                    }

                    &:focus {
                        opacity: 0.8;
                        outline: 1px solid transparent;
                    }

                    .action-title {
                        margin: 0;
                    }

                    .action-icon-block {
                        width: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }

        .tfw-logo-block {
            width: calc(100% + 100px);
            margin-top: auto;
            padding: 10px;
            margin-left: -40px;
            background-color: black;

            >img {
                width: 40%;
                max-height: 60px;
                margin-left: 10px;
            }

            >p {
                line-height: 1em;
            }
        }
    }

    .dash-main {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 0;
        padding-left: 40px;
        padding-right: 40px;
        box-sizing: border-box;
        min-width: 0;
        background-color: #fff;
        border-radius: 20px;
        overflow: hidden;
        margin: 20px;
        box-sizing: border-box;
        position: relative;

        .dash-header {
            display: flex;
            flex-direction: row;
            align-items: center;

            &.alert-margin {
                margin-top: 58px;
            }

            .header-content {
                display: flex;
                flex: 1;
                align-items: center;
            }

            .employee-lookup {
                max-width: 30vw;
                z-index: 1;
                width: 100%;
                flex: 1;
                -ms-flex: 1 1 auto;
                
                
                input {
                    border-top-width: 0px;
                    border-left-width: 0px;
                    border-right-width: 0px;
                    border-radius: 0;
                }
            }

            .search-icon {
                color: #CCCCCC;
            }
            
            h1 {
                font-size: 2.1em;
            }

            .stats-pill {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
                background-color: #FFCCCC;
                border-radius: 30px;
                height: 51px;

                .profile-image-x-sm {
                    width: 40px;
                    height: 40px;
                }

                .stats-coins {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 10px;
                    padding-left: 12px;
                    padding-right: 12px;

                    .coins-icon {
                        padding-left: 5px;
                        margin-right: 10px;
                    }

                    > div {
                        border-left: 1px solid #FF9999;
                    }

                    > div, > svg {
                        padding-left: 10px;
                        margin-right: 10px;
                    }

                    > svg {
                        font-size: 1.5em;
                        margin-right: 15px;
                    }

                    .value, .type {
                        font-size: 0.8em;
                    }

                    .value {
                        font-weight: bold;
                    }

                    .type {
                        font-weight: lighter;
                        color: #666666;
                    }
                }

                p {
                    margin: 0;
                }

                svg {
                    font-size: 1.2em;
                }

                .sub-pill {
                    display: flex;
                    flex-direction: row;
                    background-color: #FF6666;
                    border-radius: 30px;
                    height: 100%;
                    align-items: center;
                    color: white;
                    padding-left: 7px;
                    padding-right: 25px;
                    position: relative;
                    z-index: 1;

                    *:focus {
                        outline: 1px solid transparent;
                    }

                    svg {
                        margin-left: 25px;
                    }

                    .pill-menu {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 30px;
                        z-index: -1;
                        background-color: #FF6666;
                        padding-top: 20px;
                        border-bottom-left-radius: 20px;
                        border-bottom-right-radius: 20px;

                        >div:hover {
                            background-color: #FF0000;
                        }

                        >div:last-of-type {
                            border-bottom-left-radius: 20px;
                            border-bottom-right-radius: 20px;
                        }
                    }
                }
            }
        }

        .dash-content {
            display: flex;
            flex: 1;
            min-height: 0;
            position: relative;

            &.hide-content > div:first-of-type {
                display: none;
            }
        }
    }
}