.modal {
    //background-color: rgba(255, 204, 204, 0.9);
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    text-align: left;
    z-index: 2;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 75px;
    padding-right: 75px;
    box-sizing: border-box;

    &.modal-white >.modal-box {
        background: white;
        
        > .modal-x-wrapper > .modal-x {
            background-color: white;
        }
    }

    > .modal-box {
        position: relative;
        height: 100%;
        padding: 12px;
        box-sizing: border-box;
        //background: linear-gradient(180deg, #FF6666 0%, #FF6666 5%, #FF0000 100%);
        border-top-left-radius: 30px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;

        &.no-close {
            border-radius: 30px; 
        }

        > .modal-x-wrapper {
            overflow-x: visible;

            > .modal-x {
                position: absolute;
                top: 0px;
                right: -50px;
                font-size: 2em;
                color: white;
                background-color: #FF6666;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-left: 0.1px solid #FF6666;
    
                > svg {
                    cursor: pointer;
                }
            }
        }

        .modal-title-block {
            padding-top: 0px;
            padding-bottom: 30px;
        }

        .content {
            background-color: white;
            border-radius: 20px;
        }

        .modal-list {
            border-radius: 20px;
            padding-left: 8px;
        }
    }
}