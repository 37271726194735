.badged-icon {
    position: relative;

    .badge {
        position: absolute;
        top: -7px;
        right: -11px;
        border-radius: 50%;
        background-color: red;
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px;

        >span {
            font-size: 0.8em;
        }
    }
}
