.milestone-progress {
    display: flex;
    flex-direction: row;
    align-items: center;

    .milestone-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-left: 10px;
        margin-right: 10px;
    }

    .milestone-title {
        text-align: center;
        font-size: 24px;
        margin-bottom: 10px;
        color: #666666;
    }

    .complete {
        font-weight: bold;
        text-align: center;
        font-size: 28px;
    }

    &.step-progress {
        .progress-row .progress-text {
            font-size: 18px;
        }

        .step-title {
            color: #666666;
            text-align: center;
            font-size: 18px;
            white-space: nowrap;
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .progress-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        >.progress-bar-wrapper {
            position: relative;
            width: 100%;
        }

        .progress-text {
            font-size: 21px;
            color: #666666;
        }

        .progress-bar {
            height: 8px;
            width: 100%;
            position: relative;
            border-radius: 100px;
            background-color: #CCCCCC;
            
            .progress {
                position: relative;
                height: 100%;
                background-color: red;
                border-radius: 100px;
            }
        }
    }
}