
.ScrollbarsCustom {
    width: unset !important;
    padding-right: 10px !important;

    .ScrollbarsCustom-Scroller {
        padding-right: 20px !important;
        margin-right: -21px !important;
        outline: none;
    }

    .ScrollbarsCustom-Content {
        padding-right: 20px !important;
    }

    .ScrollbarsCustom-TrackY {
        top: 0 !important;
        height: calc(100% - 20px) !important;
        width: 4px !important;
        right: 5px !important;
        overflow: visible !important;
        background-color: #FFCCCC !important;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 8px;
    }

    .ScrollbarsCustom-ThumbY {
        background-color: #FF0000 !important;
        width: 6px !important;
        left: -1px !important;
        position: relative !important;
    }
}