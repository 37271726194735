.milestones {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;

    h1 {
        text-align: center;
        font-size: 50px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    h2 {
        color: #666666;
    }

    .badge {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .milestone-grid {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
    }

    .milestone-grid-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        flex-basis: 60%;
    }

    .getting-started-card {
        display: flex;
        flex-direction: column;
        flex: 1;
        flex-basis: 40%;
        max-width: 500px;
        margin-bottom: auto;
    }

    .getting-started-card {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .step-progress {
        margin-bottom: 25px;
    }
}