.btn {
    border-radius: 8px;
    background-color: black;
    color: white;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 0.9em;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
}

.btn-square {
    border-radius: 0;
}

.btn-alt {
    color: red;
    background-color: white;
}

.btn-light {
    color: white;
    background-color: rgba(255, 255, 255, 0.3);
}

.btn-flex {
    flex-grow: 1;
}

.btn-lg {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.btn-xlg {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 1.4em;
}

.btn-modal {
    width: 250px;
    font-size: 1.5em;
    padding: 10px;
    font-weight: bold;
}

.btn-negative {
    color: #CCCCCC;
    background-color: #F2F2F2;
    font-weight: 300;
}

.btn-negative-alt {
    color: #666666;
    background-color: #CCCCCC;
    font-weight: bold;
}

.btn-positive {
    color: white;
    background-color: red;
    font-weight: bold;
}

.btn-positive-alt {
    color: white;
    background-color: #FF6666;
    font-weight: bold;
}

.btn-alert {
    background-color: red;
    font-weight: bold;
}

.spaced-buttons {
    > .btn {
        margin-left: 3px;
        margin-right: 3px;
    }
}
