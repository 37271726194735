.rte {
    display: flex;
    flex-direction: column;
    min-height: 0;
    
    .editor {
        color: #666666;
        overflow-y: auto;
        font-family: 'Wales Sans Body', sans-serif !important;

        h1, h2, h3 {
            color: #FF0000;
        }

        h1 {
            font-size: 2.2em;
            margin-bottom: 0.1em;
        }
    }
}