.notification-item {
    position: relative;
    border-radius: 20px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    margin-bottom: 15px;

    .comment {
        margin-left: 10px;
        margin-right: 10px;
    }

    .details-expand {
        padding-right: 5px;
    }

    .company-value-card {
        width: 300px;
        min-width: 225px;
        margin-top: 0;
        margin-left: 20px;
        background-color: white;
        color: red;
        outline: none;
    }

    .coins-icon {
        top: 5px;
        position: relative;
        margin-right: 4px;
        width: 18px;
    }
}