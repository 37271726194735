.recognition {
    .company-values-list {
        flex-wrap: wrap;
    }

    .send-btn {
        width: 150px;
    }

    .redeem-medal {
        flex: 1;
        width: 100%;
        justify-content: center;
        display: flex;

        >img {
            height: 100%;
            object-fit: contain;
        }
    }

    .employee-section {
        flex-basis: 50%;
    }

    .coin-section {
        flex-basis: 50%;
    }

    .confirm-modal {
        background-color: rgba(255,255,255, 0.8);

        .modal-box {
            background-color: white;
            box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
            padding-top: 40px;
            padding-bottom: 40px;
            padding-left: 60px;
            padding-right: 60px;

            .text-xlg {
                font-weight: bold;
            }

            .text-md {
                color: #666666;
                margin-bottom: 40px;
                white-space: pre-line;
            }

            .btn {
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 60px;
                padding-right: 60px;
            }

            .btn:first-of-type {
                margin-left: 0;
            }

            .modal-x > svg {
                color: red;
            }

            .modal-x {
                right: 25px !important;
                top: 25px !important;
            }
        }
    }
}

.company-value-card {
    width: 250px;
    min-width: 250px;
    border-radius: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border: 2px solid #FFFFFF;
    outline: none;

    &.active {
        border: 2px solid #FF9999;
    }
}