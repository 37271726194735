.modal {
    &.popover-modal {
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 204, 204, 0.9);
        > .modal-box {
            height: unset;
            background: black;
            border-radius: 8px;
            padding-left: 25px;
            padding-right: 70px;
        }

        > .modal-box > .modal-x-wrapper {
            > .modal-x {
                background-color: transparent;
                border-left: none;
                top: 10px;
                right: 10px;
            }
        }

        &.light {
            background-color: rgba(255, 255, 255, 0.98);

            > .modal-box {
                background: transparent;

                .text-md {
                    color: #666666;
                }
            }
        }
    }
}