.terms-modal {
    &.modal {
        background-color: rgba(255,255,255, 0.95);
    }

    .continue-btn {
        max-width: 250px;
    }

    h1 {
        font-size: 2.1em;
        color: red;
        text-align: center;
    }

    .rte {
        height: auto;
        border: none;
        background: none;

        .editor:after {
            content: none;
            background-image: none;
        }
    }

    .terms-content {
        flex-grow: 1;
        margin-bottom: 20px;
        padding-right: 40px !important;

        .ScrollbarsCustom-Content {
            padding-right: 40px !important;
        }

        .DraftEditor-editorContainer {
            border: none;
        }
    }

    > .modal-box {
        background: none !important;
        margin-left: 15% !important;
        margin-right: 15% !important;
        overflow-y: none !important;
        flex-grow: 1;
    }
}