.modal.confirm-details {
    background-color: rgba(255, 204, 204, 0.9);

    > .modal-box {
        padding: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
        height: auto;
        > div {
            width: 85%;
        }
    }

    .detail-row {
        display: flex;
        border-bottom: 1px solid red;
        padding: 16px;

        > span {
            width: 50%;
        }
    }

    .button-box {
        border-top: 1px solid red;
    }

    .profile-image-xlg {
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
    }
}