.activity {
    display: flex;
    flex-direction: column;
    flex: 1;
    
    .dash-scroll {
        margin-left: -20px;
        margin-right: -20px;

        .ScrollbarsCustom-Content {
            padding-left: 20px !important;
            padding-top: 0px !important;
            padding-bottom: 20px !important;
            padding-right: 20px !important;
        }

        .ScrollbarsCustom-Wrapper::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            pointer-events: none;
            background-image: linear-gradient(to bottom, rgba(255,255,255, 0), white 100%);
            width: 100%;
            height: 20%;
        }
    }
}