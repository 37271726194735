.landing {
    height: 100vh;
    background: linear-gradient(225deg, #FF6666 0%, #FF0000 100%);
    animation: animatedGradient 120s infinite linear;
    background-size: 400% 400%;
    color: white
}

.tfw-logo {
    position: absolute;
    top: 50px;
    left: 50px;
    height: 65px;
}

.background-circle-1 {
    animation: rotation 120s infinite linear;
    position: absolute;
    transform-origin: 20% 20%;
    top: -35vh;
    left: -15vh;
}

.background-circle-2 {
    animation: rotation 120s infinite linear;
    position: absolute;
    transform-origin: 80% 80%;
    right: -15vh;
    bottom: -35vh;
}

.background-circle-3 {
    animation: rotation 120s infinite linear;
    position: absolute;
    transform-origin: 80% 20%;
    top: -35vh;
    right: -15vh;
}

.background-circle-4 {
    animation: rotation 120s infinite linear;
    position: absolute;
    transform-origin: 20% 80%;
    left: -15vh;
    bottom: -35vh;
}

.title-row {
    z-index: 1;
    width: 100%;
    height: 100%;
}

.title-logo {
    width: 350px;
    height: 350px;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
}

@keyframes animatedGradient {
    0%{background-position: 0% 100%}
    50%{background-position: 100% 0%}
    100%{background-position: 0% 100%}
}