.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.list-loader {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}