.fullpost-modal {
    .action-bar .card-menu {
        right: unset;
    }

    .kudos-arrow {
        font-size: 72px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .kudos-post-block {
        > p {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
}