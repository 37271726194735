.employee-lookup {
    color: red;
    
    .employee-lookup-list {
        background-color: white;
    }

    .employee-lookup-item:hover {
        background-color: #FFCCCC;
    }
}

.multi-employee-lookup {
    .profile-image-x-sm {
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        width: 25px;
        height: 25px;
    }

    .selected-item {
        border: 1px solid grey;
        padding: 5px;
        border-radius: 10px;
        margin: 5px;
        margin-top: 0px;
    }
}