.like-list {
    position: absolute;
    top: -8px;
    right: -22px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    text-align: left;

    > div {
        background-color: white;
        padding: 20px;
    }

    .list {
        position: relative;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
        min-width: 250px;
        overflow: hidden;
        padding-bottom: 10px;

        .loader {
            margin-top: 20px;
        }

        &.loading {
            min-height: 120px;
        }

        .ScrollbarsCustom {
            min-height: 200px;
            height: unset !important;

            .ScrollbarsCustom-Content {
                padding-left: 0px !important;
                padding-right: 0px !important;
                padding-bottom: 0px !important;
            }
        }
    }

    .close-icon {
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        font-size: 24px;
        box-shadow: 4px 1px 4px 0px rgba(0,0,0,0.09);
        z-index: 1;
    }

    .title {
        font-weight: bold;
        margin-bottom: 10px;
    }

    .like-item {
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: left;
        margin-right: 20px;

        >div {
            white-space: pre-wrap;
        }

        img {
            margin-left: 0;
        }
    }
}