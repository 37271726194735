.social-post-modal {
    margin-top: 10px;
    padding-bottom: 15px;

    .post-title {
        font-size: 2em;
        margin-bottom: 0.1em;
        font-weight: bold;
    }

    img {
        object-fit: contain;
        width: 100%;
        height: 300px;
    }

    .rte {
        background-color: transparent;
        border: none;
    }

    > .modal-box .ScrollbarsCustom-Content {
        padding: 0 !important;
    }

    .ScrollbarsCustom {
        box-sizing: border-box;
        width: 100% !important;

        .ScrollbarsCustom-Content {
            min-height: unset !important;
            padding-right: 30px !important;
        }

        .ScrollbarsCustom-Scroller {
            width: 100%;
        }

        .ScrollbarsCustom-TrackY {
            right: 15px !important;
        }

        &.trackYVisible {
            box-sizing: content-box;

            .ScrollbarsCustom-Scroller {
                padding-right: 17px;
            }
        }
    }
}