.comment-section {
    padding-left: 15px;

    .send-icon {
        cursor: pointer;
        width: 30px;
        height: 30px;
        color: red;
        margin-left: 10px;
    }

    textarea {
        outline: none;
        border: none;
        font-weight: 300;
        color: #999999;
    }

    .add-comment {
        margin-top: 20px;
        margin-bottom: 20px;
        align-items: center;
        max-width: 960px;
    }

    .edit-comment {
        margin-top: 0px;
        margin-bottom: 20px;
        align-items: center;
        max-width: 960px;
    }

    .comment-input {
        border-radius: 25px;
        box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
        color: #999999;
        flex: 1;
        padding: 10px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .comment-input__suggestions {
        top: 15px !important;
        border-radius: 10px;
        box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);

        .comment-input__suggestions__item {
            padding-left: 10px;
            padding-right: 25px;
        }

        .comment-input__suggestions__item:first-of-type {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        .comment-input__suggestions__item:last-of-type {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        .comment-input__suggestions__item:hover {
            background-color: red;

            .suggestion-text {
                > p {
                    color: white;
                }
            }
        }

        .profile-image-x-sm {
            margin-top: 5px;
            margin-bottom: 5px;
        }

        .suggestion-text {
            > p {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }
}

.comment-item {
    max-width: 960px;

    &.highlighted {
        > div:first-of-type .comment {
            background-color: #FF9999;
            color: white;

            .card-menu {
                color: red;
            }
        }
    }
    
    .comment {
        border-radius: 25px;
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.16);
        color: #999999;
        flex: 1;
        padding: 15px;

        .comment-text {
            font-weight: 300;
            margin-top: 5px;
            white-space: pre-wrap;
        }
    }

    .menu-icon {
        font-size: 20px;
        color: '#CCCCCC';
        margin-right: 10px;
    }

    .action-row {
        justify-content: flex-end;
        padding-right: 25px;
        margin-top: 6px;
        margin-bottom: 14px;

        .like-list {
            right: 260px;
            top: -22px;
        }

        > div {
            margin-left: 15px;
            color: #999999;
        }
    }
}