.notification-modal-list {
    .ScrollbarsCustom-Content {
        padding-left: 20px !important;
        padding-top: 20px !important;
        padding-bottom: 10px !important;
    }

    > .modal-box > .content > .modal-title-block {
        padding-bottom: 0px;
    }

    .notification-modal-list {
        .notification-item {
            background-color: rgba(255, 255, 255, 0.3);
            padding: 10px;
            text-align: center;
            margin-top: 10px;

            &:first-of-type {
                margin-top: 0px;
            }

            .time-since {
                position: absolute;
                right: 30px;
            }

            .text-link {
                margin-left: 0;
                margin-top: 5px;
            }
        }
    }
}