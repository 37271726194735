.tutorial {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-left: calc(5% + 12px);
    padding-right: calc(5% + 12px);
    padding-top: 4%;
    padding-bottom: 5%;
    box-sizing: border-box;
    background-color: white;
    border: 12px solid #FF9999;

    &.landing {
        justify-content: center;
        align-items: center;
        background: white;
        border: none;
        color: red;

        .logo-text {
            color: black;
            font-size: 84px;
            margin: 0;
            font-weight: bold;
        }

        .title {
            font-size: 48px;
            font-weight: bold;
            margin: 10px;
        }

        .logo {
            min-height: 0;
            object-fit: contain;
            flex: 1;
            margin-top: 30px;
            margin-bottom: 10px;
        }

        @media screen and (max-height: 675px) {
            .logo {
                display: none;
            }
        }

        .content {
            font-weight: bold;
            max-width: 700px;
            text-align: center;
        }
    }

    .close-icon {
        position: absolute;
        right: 75px;
        top: 55px;
        font-size: 3em;
        cursor: pointer;
    }

    h2 {
        color: #FF0000;
        font-size: 48px;
        margin: 0;
    }

    h1 {
        color: #FF9999;
        font-size: 38px;
        margin: 0;
    }

    .page {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .text-block {
            width: 75%;
        }

        img {
            flex: 1;
            width: 80%;
            object-fit: contain;
            min-height: 0;
            margin-bottom: 5px;
            margin-top: 5px;
        }

        .multi-image {
            max-height: 80%;
        }
    }

    .BrainhubCarousel__container {
        display: flex;
        flex: 1;
        flex-direction: column;

        .BrainhubCarousel__trackContainer {
            height: 100%;
            justify-content: space-between;
            display: flex;
        }

        .BrainhubCarousel__track {
            overflow: visible;
        }

        .BrainhubCarousel {
            flex: 1;

            .BrainhubCarousel__arrows {
                background-color: transparent;
                padding: 32px;

                >span {
                    border-color: #FF0000;
                    border-width: 16px 16px 0 0;
                    padding: 14px;
                }

                &:disabled {
                    >span {
                        border-color: #FFCCCC;
                    }
                }
            }
        }

        .BrainhubCarousel__dot {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #FFCCCC;
            margin-left: 5px;
            margin-right: 5px;
        }

        .BrainhubCarousel__dot--selected {
            background-color: #FF0000;
        }

        .BrainhubCarousel__dot::before {
            display: none;
        }
    }
}