.confirm-rec-modal {
    padding: 0px !important;
    padding-bottom: 20px !important;

    > .modal-box {
        width: 100%;
        height: 100% !important;
    }

    .details-block {
        flex: 1;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .details-popover {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .value-text {
        font-size: 20px;
        color: #666666;
        margin-top: 10px;
    }

    .arrow-icon {
        font-size: 4em;
        margin-left: 20px;
        margin-right: 20px;
    }
}