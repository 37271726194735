.admin-page {
    padding-top: 20px;
    padding-bottom: 20px;

    .ScrollbarsCustom-Content {
        display: flex;
        flex-direction: column;
    }

    .admin-stats {
        flex-wrap: wrap;
    }

    .menu-cards {
        flex-wrap: wrap;
    }

    .menu-card {
        border-radius: 8px;
        margin: 10px;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: 0;

        > svg {
            margin-right: 10px;
        }
    }

    .stats-card {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        margin: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);

        .stats-text {
            color: #666666;
            font-weight: bold;
            position: relative;

            .drop-icon {
                margin-left: 2px;
            }

            .drop-list {
                background-color: white;
                position: absolute;
                right: 0;
                margin-top: 5px;
                padding: 10px;
                padding-top: 6px;
                padding-bottom: 6px;
                box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
                border-radius: 6px;

                .drop-list-item {
                    cursor: pointer;
                    margin-top: 5px;
                    margin-bottom: 5px;

                    &:hover {
                        color: red;
                    }
                }
            }
        }

        .text-xxlg {
            font-size: 4em;
            font-weight: 300;
        }

        &.stats-card-text {
            align-items: flex-start;
            justify-content: flex-start;
        }
    }

    .admin-button {
        margin-left: auto;
        padding-left: 50px;
        padding-right: 50px;
    }

    .distributions-list {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        padding: 20px;
        margin: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);

        .title {
            color: #666666;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .ScrollbarsCustom {
            display: flex;
            flex-grow: 1;
        }

        .ScrollbarsCustom .ScrollbarsCustom-Content {
            padding-right: 0px !important;
        }

        .ScrollbarsCustom .ScrollbarsCustom-Scroller {
            padding-right: 0px !important;
        }

        .ScrollbarsCustom {
            padding-right: 0px !important;
        }

        .ScrollbarsCustom-Wrapper {
            right: 0px !important;
        }

        .distribution-item,
        .list-header {
            flex-direction: row;
            display: flex;
            padding-right: 9px;

            >p {
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
        }

        .distribution-item:nth-child(odd) {
            background-color: rgba(255, 204, 204, 0.4);
        }

        .distribution-item > .modal {
            position: fixed;
        }

        .list-header > p {
            color: #666666;
        }

        .actions {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            > .disabled {
                color: #FF9999;
            }
        }
    }

    .confirm-modal {
        background-color: rgba(255,255,255, 0.8);

        .modal-box {
            background-color: white;
            box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
            padding-top: 40px;
            padding-bottom: 40px;
            padding-left: 60px;
            padding-right: 60px;

            .text-xlg {
                font-weight: bold;
            }

            .text-md {
                color: #666666;
                margin-bottom: 40px;
                white-space: pre-line;
            }

            .employee-lookup .text-md {
                margin-bottom: 0;
            }

            .employee-lookup {
                z-index: 1;
            }

            .btn {
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 60px;
                padding-right: 60px;
            }

            .btn:first-of-type {
                margin-left: 0;
            }

            .modal-x > svg {
                color: red;
            }

            .modal-x {
                right: 25px !important;
                top: 25px !important;
            }
        }
    }
}