.gift-card-item {
    background-color: rgba(255, 255, 255, 0.3);
    padding: 10px;
    margin-top: 10px;
    box-sizing: border-box;
    border-radius: 15px;
    outline: none;

    img, svg {
        max-width: 100%;
        height: 150px;
        object-fit: contain;
        font-size: 2em;
    }

    .btn {
        width: 60%;
    }
}

.gift-card-category {
    width: 200px;
    border-radius: 10px;
    padding: 15px;
    padding-left: 20px;
    padding-right: 20px;
    color: #CCCCCC;
    outline: none;

    &.active {
        color: red;
    }

    img {
        height: 40px;
    }

    >div {
        width: 50%;
        display: flex;
        justify-content: center;
        font-size: 0.9em;
    }
}