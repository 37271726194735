.terms-modal {
    h1 {
        font-size: 2.1em;
        color: red;
    }

    .post-content {
        height: 50vh !important;
        margin-bottom: 20px;
    }

    > .modal-box {
        margin-left: 25% !important;
        margin-right: 25% !important;
        overflow-y: none !important;
    }

    .mandatory-post-img {
        max-width: 100%;
        max-height: 500px;
    }
}

.mandatory-post-modal .ScrollbarsCustom {
    height: 100% !important;
}